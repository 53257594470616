/* Card Version */
.bodyContainer {
  margin: 24px;
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.14em;

  .wrapper {
    display: flex;
    flex-direction: column;

    .logo {
      display: flex;
      justify-content: center;
      padding-top: 40px;

      & svg {
        width: 59px;
      }
    }

    .tracrId {
      display: flex;
      flex-direction: column;
      align-items: center;

      & .heading {
        font-size: 14px;
        font-weight: 500;
        text-transform: uppercase;
        color: #fff;
      }

      & .id {
        padding-top: 8px;
        font-size: 14px;
        font-weight: 700;
        color: #fff;
      }
    }

    .imageAndCopy {
      font-size: 14px;

      .headerImageContainer {
        display: flex;
        justify-content: center;
        width: 100%;

        .headerImage {
          justify-content: center;
          margin: 24px 0;
          width: 264px;
          height: 198px;
        }
      }
    }
  }
}

@media (min-width: 600px) {
  .bodyContainer {
    font-size: 14px;
    max-width: 1016px;
    height: 100%;

    .wrapper {
      height: 100%;

      .logo {
        padding-top: 52px;

        & svg {
          width: 65px;
        }
      }

      .tracrId {
        & .id {
          font-size: 14px;
        }
      }

      .imageAndCopy {
        display: flex;
        margin-top: 24px;

        .headerImageContainer {
          width: auto;
          .headerImage {
            display: flex;
            width: 160px;
            height: 125px;
            padding-top: 0;
            margin: 0 16px 0 0px;
          }
        }
      }
    }
  }
}