.searchContainer {
  .wrapper {
    display: flex;
    flex-grow: 0;
    width: 100%;
    height: 48px;
    border: 1px solid #939393;
    border-radius: 2px;
    box-sizing: border-box;

    &.focused {
      border: 1px solid #ffffff;
    }

    input {
      display: flex;
      box-sizing: border-box;
      width: 100%;
      border: none;
      padding: 0 0 0 24px;
      outline: none;
      font-family: 'Montserrat', sans-serif;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0.2px;
      background-color: transparent;
      color: #fff;
    }

    .buttonClear {
      display: none;
      flex-shrink: 0;
      justify-content: center;
      align-items: center;
      width: 48px;
      margin-left: auto;
      cursor: pointer;

      &.visible {
        display: flex;
      }
    }

    .buttonSubmit {
      display: flex;
      flex-shrink: 0;
      justify-content: center;
      align-items: center;
      width: 47px;
      border-left: 1px solid #939393;
      cursor: pointer;

      &.focused {
        border-left: 1px solid #fff;
      }
    }

    .infoIcon {
      padding: 7px 10px 10px 10px;
      cursor: pointer;
      display: none;

      &.visible {
        display: flex;
        visibility: visible;
      }

      svg path[fill] {
        fill: #D9D9D9;
      }

      svg path[stroke] {
        stroke: #D9D9D9;
      }
    }
  }
}