.notFoundContainer {
  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    .text {
      font-family: 'Montserrat';
      text-align: center;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0.2;
      color: #fff;
      margin-bottom: 20px;
    }
  }
}

@media (min-width:834px) {
  .notFoundContainer {
    .wrapper {
      .text {
        font-size: 16px;
        line-height: 36px;
        letter-spacing: 0;
        font-weight: 400;
        margin-bottom:0
      }
    }
  }
}