.inscriptionReportNumbersContainer {
  display: flex;
  font-size: 12px;
  line-height: 16px;
  font-weight: 700;
  flex-direction: column;
  gap:8px;


  .info {
    display: flex;
    label {
      font-weight: 500;
      text-wrap-mode: nowrap;
      margin-right: 40px;
      width: 227px;
      letter-spacing: 1px;
    }

    span {
      width: 140px;
      letter-spacing: 0.2px;
      word-break: break-word;
    }
  }

}

@media (min-width: 600px) {
  .inscriptionReportNumbersContainer {
    width: 300px;
    font-size: 14px;
    letter-spacing: 0.2px;
    align-content: center;
    flex-wrap: wrap;
    flex-direction: row;
    gap:16px;

    .info {
      margin-right: 16px;

      label {
        margin-right: 16px;
        width: 180px;
      }

      span {
        width: 150px;
      }
    }
  }
}