.cardContainer {
  width: 100%;
  .wrapper {
    border-radius: 30px;
    background: #232323;
    background-image: url('../../../../public/images/backgrounds/diamond_bg.png');

    .positioner {
      display: flex;
      flex-direction: column;
      border-radius: 30px;
      box-shadow: 0px 3px 0px rgba(0, 0, 0, 0.08);

      .heading {
        justify-content: center;
        align-items: center;
        min-height: 64px;
        background-color: #2d2d2d;
        border-radius: 30px 30px 0px 0px;
        padding: 0 24px;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 2.88px;
        text-transform: uppercase;
        color: #fff;
        box-shadow: 0px 3px 0px rgba(0, 0, 0, 0.08);
        line-height: 20px;
        display: flex;

        > span {
          display: -webkit-box;
          position: relative;
          box-orient: vertical;
          -webkit-box-orient: vertical;
          text-align: center;
          > div {
            display: inline;
            img{
              top: 1px;
              position: relative;
              overflow: inherit;
              margin-right: 8px;
            }
          }
         }
      }

      .footer {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 48px;
        border-radius: 0px 0px 30px 30px;
        text-align: center;
        font-size: 10px;
        font-weight: 600;
        letter-spacing: 0.07em;
        text-transform: uppercase;
        background-color: #2d2d2d;
        color: #18bc98;
        opacity: 0.8;
        cursor: pointer;

        &.expanded {
          border-radius: 0;
        }

        & svg {
          margin-left: 8px;
        }
      }
    }
  }
}

@media (min-width: 600px) {
  .cardContainer {
    .wrapper {
      max-width: 1016px;
      margin: 0 auto;
      height: 100%;

      .positioner {
        max-width: 1016px;
        margin: 0 auto;
        height: 100%;

        .heading {
          font-size: 12px;
          letter-spacing: 2.88px;
          line-height: 27.08px;
          max-height: 64px;
          > span {
            line-clamp: 2;
            -webkit-line-clamp: 2;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 500px;
            max-height: 48px;
            > div {
              img{
                top: 3px;
              }
            }
          }
        }

        .footer {
          min-height: 54px;
          font-size: 12px;
        }

      }
    }
  }
}