.diamondListContainer {
  .labelContainer {
    margin: 0 auto 24px;
    text-align: center;

    .label {
      display: block;
      font-size: 16px;
      font-weight: 400;
      font-family: "Montserrat";
      line-height: 19.20px;
      letter-spacing: -1.9;
      color: #FFFFFF;

    }
  }

  .listContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 auto;

    .listItem {
      width: 100%;
      margin-bottom: 20px;
    }
  }
}


@media (min-width: 600px) {
  .diamondListContainer {
    .labelContainer {
      margin: 140px auto 50px;

      .label {
        font-size: 24px;
        line-height: 28.8px;
      }
    }

    .listContainer {
      max-width: 560px;

      .listItem {
        width: 100%;
        margin-bottom: 40px;
        display: flex;
      }
    }
  }
}



@media (min-width: 1168px) {
  .diamondListContainer {
    .listContainer {
      max-width: 1168px;

      .listItem {
        width: 48%;
        margin-bottom: 40px;
        display: flex;
        align-items: stretch;
      }
    }
  }
}