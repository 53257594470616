.thumbnailContainer {
  border: 1px solid #ffffff3d;
  background-color: #232323;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  .media {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    display: none;
    &.visible {
      display: flex;
    }
  }
}